<template>
  <el-popover
    placement="bottom"
    width="520"
    trigger="click"
    v-model="visible"
    popper-class="no-padding">
    <div class="waiting-tasks">
      <div class="tasks-bar">
        <h1>Waiting Tasks</h1>
<!--        <el-switch-->
<!--          v-model="viewAll"-->
<!--          active-color="#339933"-->
<!--          inactive-color="#cacaca"-->
<!--          active-text="View All">-->
<!--        </el-switch>-->
      </div>
      <div class="tasks-scroll">
        <div
          class="tasks-item"
          v-for="item in currentItems"
          :key="item.id"
          :class="{'readed': item.state === 1}"
          @click="gotoDetail(item)">
          <!-- <h2 class="tasks-content">{{ item.content }}</h2> -->
          <h2 class="tasks-content" v-html="item.content"></h2>
          <div class="tasks-info">
            <span style="line-height: 2">{{ item.sidNickname }}</span>
            <span style="line-height: 2">{{ item.createTime | timestamp2Date }}</span>
            <el-button v-if="messageCount > 0" round size="mini" @click.stop="clear(item)">Mark as Read</el-button>
          </div>
        </div>
      </div>
      <div class="tasks-foot">
        <el-button v-if="messageCount > 0" round  @click="clearAll">Mark All as Read</el-button>
      </div>
    </div>
    <div
      class="message-box"
      slot="reference">
      <div>
        <el-badge
          class="badge"
          :value="messageCount"
          :hidden = "messageCount > 0 ? false : true">
          <em
            :style="{color:color}"
            style="font-size:22px;"
            class="el-icon-bell">
          </em>
        </el-badge>
      </div>
    </div>
  </el-popover>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import { mapMutations } from 'vuex'

export default {
  name: 'MessageBox',
  inject: ['reload'],
  mixins: [baseMixin],
  props: {
    color: {
      type: String,
      default: '#303133'
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      visible: false,
      messageCount: 0,
      viewAll: false,
      currentItems: []
    }
  },
  watch: {
    items:{ handler () {
        this.setItems()
        this.messageCount = this.items.filter(item => item.state === 0).length
      }, deep: true} ,
    viewAll () {
      this.setItems()
    }
  },
  // mounted () {
  //   this.currentItems = this.items.filter(item => item.state === 0)
  // },
  methods: {
    ...mapMutations({
      setCurrentTag: 'setCurrentTag'
    }),
    setItems () {
      if (this.viewAll) {
        this.currentItems = this.items
      } else {
        this.currentItems = this.items.filter(item => item.state === 0)
      }
    },
    clear (item) {
      item = Object.assign({}, item, {
        state: 1
      })
      this.$emit('clear', item)
      // this.visible = false
    },
    clearAll () {
      // let data = this.items.filter(item => item.state === 0)
      // data = data.map(item => Object.assign({}, item, {
      //   state: 1
      // }))
      // this.$emit('clear', JSON.stringify(data))
      this.$emit('clearAll', 'readAll')
      // this.viewAll = false
    },
    gotoDetail (item) {
      if (item.businessLink) {
        item = Object.assign({}, item, {
          state: 1
        })
        this.$emit('clear', item)
        this.visible = false
        if (this.$route.fullPath.includes(item.businessLink)) {
          this.reload()
        } else {
          this.$router.push(`/admin/${item.businessLink}`)
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
