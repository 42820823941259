var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        width: "520",
        trigger: "click",
        "popper-class": "no-padding",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { staticClass: "waiting-tasks" }, [
        _c("div", { staticClass: "tasks-bar" }, [
          _c("h1", [_vm._v("Waiting Tasks")]),
        ]),
        _c(
          "div",
          { staticClass: "tasks-scroll" },
          _vm._l(_vm.currentItems, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "tasks-item",
                class: { readed: item.state === 1 },
                on: {
                  click: function ($event) {
                    return _vm.gotoDetail(item)
                  },
                },
              },
              [
                _c("h2", {
                  staticClass: "tasks-content",
                  domProps: { innerHTML: _vm._s(item.content) },
                }),
                _c(
                  "div",
                  { staticClass: "tasks-info" },
                  [
                    _c("span", { staticStyle: { "line-height": "2" } }, [
                      _vm._v(_vm._s(item.sidNickname)),
                    ]),
                    _c("span", { staticStyle: { "line-height": "2" } }, [
                      _vm._v(_vm._s(_vm._f("timestamp2Date")(item.createTime))),
                    ]),
                    _vm.messageCount > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { round: "", size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.clear(item)
                              },
                            },
                          },
                          [_vm._v("Mark as Read")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "tasks-foot" },
          [
            _vm.messageCount > 0
              ? _c(
                  "el-button",
                  { attrs: { round: "" }, on: { click: _vm.clearAll } },
                  [_vm._v("Mark All as Read")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "message-box",
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c(
            "div",
            [
              _c(
                "el-badge",
                {
                  staticClass: "badge",
                  attrs: {
                    value: _vm.messageCount,
                    hidden: _vm.messageCount > 0 ? false : true,
                  },
                },
                [
                  _c("em", {
                    staticClass: "el-icon-bell",
                    staticStyle: { "font-size": "22px" },
                    style: { color: _vm.color },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }